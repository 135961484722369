<template>
  <div>
    <v-dialog v-model="dialog" fullscreen scrollable>
      <v-card color="#eef2f6" flat tile>
        <v-card-title>
          <v-icon color="primary" left>mdi-shopping</v-icon>
          Nouvelle vente
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text>

          <v-row align="center" justify="center">
            <v-col cols="12" lg="8">

              <v-alert v-if="offline"
                       border="bottom"
                       class="my-3 fs-14"
                       color="warning"
                       dark
                       dense
                       icon="mdi-access-point-off"
                       prominent>
                Vous avez perdu votre connexion Internet. L'application passera automatiquement en mode
                hors ligne.
              </v-alert>

              <div class="text-end my-3">
                <v-btn color="red"
                       dark
                       depressed
                       @click="$refs.endPointingDialog.open(form.campaign,form.date,form.salepoint)">
                  <v-icon left>mdi-hand-pointing-down</v-icon>
                  Fin de travail
                </v-btn>
              </div>

              <SalesStatisticCard :form="form"
                                  :is_backup="is_backup"
                                  :pointing="pointing"
                                  :sale-statistics="saleStatistics"
                                  :stat-loading="statLoading"
                                  @getSalesStatistics="getSalesStatistics"/>


              <div v-if="!new_form" class="text-center">
                <v-btn color="primary" depressed fab x-large @click="startFilling">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <div v-else>
                <v-card class="rounded-lg shadow">
                  <v-card-title>
                    <v-spacer/>
                    <v-progress-circular :value="step*50"
                                         color="primary"
                                         rotate="100"
                                         size="50"
                                         width="6">
                      <span class="fs-15">{{ step }}</span>
                    </v-progress-circular>
                  </v-card-title>

                  <v-card-subtitle>
                    Tous les champs avec une étoile <span class="red--text">(*)</span> sont
                    obligatoires.
                  </v-card-subtitle>

                  <v-divider/>

                  <v-card-text v-if="step===1"
                               class="pa-4 pa-lg-10">

                    <div v-if="form.campaign.customer_info === 'nullable'">

                      <div class="mb-2 font-weight-medium">
                        Numéro de téléphone
                        <span class="red--text">(*)</span>
                      </div>

                      <v-text-field v-model.trim="form.customer.phone"
                                    v-number
                                    :error-messages="errors.customer_phone"
                                    clearable
                                    counter="9"
                                    dense
                                    max="10"
                                    outlined
                                    persistent-hint
                                    placeholder="Entrez le numéro de téléphone."
                                    prefix="+213"
                                    prepend-inner-icon="mdi-phone"
                                    required
                                    type="number"
                                    @input="errors.customer_phone = ''"
                      ></v-text-field>
                    </div>

                    <div v-else>
                      <div v-if="form.campaign.note_contact">
                        <div class="mb-1">
                          Choisissez un type de contact <span class="red--text">(*)</span>
                        </div>
                        <v-chip-group v-model="form.contact_type"
                                      active-class="secondary"
                                      class="mb-3 font-weight-medium"
                                      mandatory>
                          <v-chip filter value="direct" @click="form.sale_type='switch'">
                            Contact direct
                          </v-chip>
                          <v-chip filter value="indirect" @click="form.sale_type = 'none'">
                            Contact indirect
                          </v-chip>
                        </v-chip-group>
                      </div>

                      <div v-if="form.contact_type ==='direct' && form.campaign.note_contact">
                        <div class="mb-1">
                          Choisissez un type de vente <span class="red--text">(*)</span>
                        </div>

                        <v-chip-group v-model="form.sale_type"
                                      active-class="secondary"
                                      class="font-weight-medium"
                                      mandatory>
                          <v-chip v-if="form.contact_type === 'direct'" filter
                                  value="switch">Switch
                          </v-chip>
                          <v-chip filter value="sale">Fidélisation</v-chip>
                          <v-chip filter value="refusal">Refus</v-chip>
                        </v-chip-group>
                      </div>


                      <v-card v-if="form.sale_type === 'switch' || form.sale_type === 'sale'" class="rounded-lg mt-5"
                              outlined>
                        <v-card-title>
                          <span class="fs-17 primary--text">Information du client</span>
                        </v-card-title>
                        <v-card-text>

                          <div class="mb-2 font-weight-medium">
                            Nom complet
                            <span v-if="form.campaign.customer_info === 'required'" class="red--text">(*)</span>
                            <span v-else>(Optionnel)</span>
                          </div>
                          <v-text-field v-model.trim="form.customer.name"
                                        :error-messages="errors.customer_name"
                                        clearable
                                        dense
                                        outlined
                                        placeholder="Entrez le nom"
                                        prepend-inner-icon="mdi-account-outline"
                                        @input="errors.customer_name = ''"/>


                          <div class="mb-2 font-weight-medium">
                            Numéro de téléphone
                            <span v-if="form.campaign.customer_info === 'required'" class="red--text">(*)</span>
                            <span v-else>(Optionnel)</span>
                          </div>

                          <v-text-field v-model.trim="form.customer.phone"
                                        v-number
                                        :error-messages="errors.customer_phone"
                                        clearable
                                        counter="9"
                                        dense
                                        max="10"
                                        outlined
                                        persistent-hint
                                        placeholder="Entrez le numéro de téléphone."
                                        prefix="+213"
                                        prepend-inner-icon="mdi-phone"
                                        required
                                        type="number"
                                        @input="errors.customer_phone = ''"
                          ></v-text-field>
                        </v-card-text>
                      </v-card>

                      <v-card class="rounded-lg mt-5" outlined>
                        <v-card-title>
                          <span class="fs-17 primary--text">Sexe & Tranche d'âge du client</span>
                        </v-card-title>
                        <v-card-text>
                          <div class="font-weight-medium">Sexe <span
                              class="red--text">(*)</span></div>
                          <v-radio-group v-model="form.customer.gender" :error-messages="errors.customer_gender"
                                         class="mt-2"
                                         row
                                         @change="errors.customer_gender = ''">
                            <v-radio label="Homme" value="man"></v-radio>
                            <v-radio label="Femme" value="women"></v-radio>
                          </v-radio-group>
                          <div class="font-weight-medium">Tranche d'âge <span
                              class="red--text">(*)</span></div>
                          <v-radio-group v-model="form.customer.age_range"
                                         :error-messages="errors.customer_age_range"
                                         class="mt-2"
                                         row
                                         @change="errors.customer_age_range = ''">
                            <v-radio label="19-25" value="19-25"></v-radio>
                            <v-radio label="26-35" value="26-35"></v-radio>
                            <v-radio label="36-45" value="36-45"></v-radio>
                            <v-radio label="+45" value="+45"></v-radio>
                          </v-radio-group>
                        </v-card-text>
                      </v-card>

                      <v-card
                          v-if="form.sale_type === 'switch' || form.sale_type === 'refusal' || form.sale_type === 'none'"
                          class="rounded-lg mt-5"
                          outlined>
                        <v-card-title>
                          <span class="fs-17 primary--text">Produit consommé</span>
                        </v-card-title>
                        <v-card-text>

                          <div class="mb-2 font-weight-medium">Marque <span class="red--text">(*)</span>
                          </div>
                          <v-select v-model="form.original_brand_id"
                                    :error-messages="errors.original_brand_id"
                                    :items="brands"
                                    clearable
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    placeholder="Sélectionnez une marque"
                                    @change="[form.original_product_id = '',errors.original_brand_id='']"/>

                          <div class="mb-2 font-weight-medium">Produit <span
                              class="red--text">(*)</span></div>
                          <v-select v-model="form.original_product_id"
                                    :disabled="!form.original_brand_id"
                                    :error-messages="errors.original_product_id"
                                    :items="filtredProducts"
                                    clearable
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    placeholder="Sélectionnez un produit"
                                    @change="errors.original_product_id=''"/>

                          <div v-if="form.sale_type === 'refusal' || form.sale_type === 'none'">
                            <div class="mb-2 font-weight-medium">Quantité<span
                                class="red--text">(*)</span>
                            </div>
                            <v-text-field v-model="form.original_product_qty"
                                          v-number
                                          :error-messages="errors.original_product_qty"
                                          clearable
                                          dense
                                          min="1"
                                          outlined
                                          placeholder="Entrez le nombre des produits"
                                          type="number"
                                          @input="errors.original_product_qty=''"/>
                          </div>

                        </v-card-text>
                      </v-card>

                      <v-card v-if="form.sale_type === 'switch' || form.sale_type === 'sale'" class="rounded-lg mt-5"
                              outlined>
                        <v-card-title>
                          <span class="fs-17 primary--text">Produit acheté</span>
                        </v-card-title>
                        <v-card-text>

                          <div class="mb-2 font-weight-medium">
                            Marque
                            <span class="red--text">(*)</span>
                          </div>
                          <v-select v-model="form.campaign_brand_id"
                                    :error-messages="errors.campaign_brand_id"
                                    :items="form.campaign.brands"
                                    clearable
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    placeholder="Sélectionnez la marque switch"
                                    @change="[form.campaign_product_id = '',errors.campaign_brand_id = '']"/>

                          <div class="mb-2 font-weight-medium">
                            Produit
                            <span class="red--text">(*)</span>
                          </div>
                          <v-select v-model="form.campaign_product_id"
                                    :disabled="!form.campaign_brand_id"
                                    :error-messages="errors.campaign_product_id"
                                    :items="filtredCampaignProducts"
                                    clearable
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    placeholder="Sélectionnez le produit switch"
                                    @change="errors.campaign_product_id=''"/>

                          <div class="mb-2 font-weight-medium">Quantité<span
                              class="red--text">(*)</span>
                          </div>
                          <v-text-field v-model="form.campaign_product_qty"
                                        v-number
                                        :error-messages="errors.campaign_product_qty"
                                        clearable
                                        dense
                                        min="1"
                                        outlined
                                        placeholder="Entrez le nombre des produits"
                                        type="number"
                                        @input="errors.campaign_product_qty=''"/>

                          <v-alert border="bottom" class="fs-14" color="warning" dark text>
                            <strong> Remarque : </strong>
                            La quantité minimale pour gagner un cadeau est : {{ form.campaign.min_qty_product_win }}
                          </v-alert>

                        </v-card-text>
                      </v-card>
                    </div>

                  </v-card-text>

                  <v-card-text v-if="step===2" class="pa-4 pa-lg-10">

                    <div v-if="form.campaign.choice_gift_type === 'none'" class="mb-4">
                      Cette partie consiste à lancer le jeu dans lequel se trouvent les cadeaux :
                    </div>

                    <div v-if="form.campaign.choice_gift_type !== 'none'" class="mb-4">
                      Choisissez un cadeau <span class="red--text">(*)</span>
                    </div>

                    <div v-if="
                   (form.sale_type === 'sale' && form.campaign_product_qty <  form.campaign.min_qty_product_win)
                   ||
                   (form.sale_type === 'switch' && form.campaign_product_qty <  form.campaign.min_qty_product_win)">
                      <v-alert border="bottom" class="fs-14" color="warning" dark text>
                        <strong> Remarque : </strong>
                        La quantité minimale pour jouer et gagner un cadeau est :
                        {{form.campaign.min_qty_product_win}}
                      </v-alert>
                    </div>

                    <div v-else>
                      <div v-if="form.campaign.with_game">

                        <JackpotGame v-if="form.campaign.game === 'jackpot'"
                                     :campaign="form.campaign"
                                     :form.sync="form"/>

                        <SpintowinGame v-if="form.campaign.game === 'spintowin'"
                                       :campaign="form.campaign"
                                       :form.sync="form"/>
                      </div>

                      <div v-else>

                        <div v-if="form.gift.is_digital && form.gift.pivot.recipient_digital_amount === 'animator'">
                          <v-alert v-if="!$store.state.user.gifty_id" class="fs-14" dense type="warning">
                            {{ $store.state.user.name }}, Vous n'avez pas de compte GIFTY!
                          </v-alert>
                        </div>

                        <v-alert v-if="form.campaign.choice_gift_type === 'none'" border="bottom" class="fs-14"
                                 color="primary" dark prominent>
                          Cette campagne n'a pas de jeux,

                          <p v-if="form.gift.is_digital" class="font-weight-medium mb-0">
                          <span v-if="form.gift.pivot.recipient_digital_amount === 'consumer'">
                             Le consommateur
                          </span>
                            <span v-if="form.gift.pivot.recipient_digital_amount === 'salepoint'">
                             Le point de vente
                          </span>
                            <span v-if="form.gift.pivot.recipient_digital_amount === 'animator'">
                             L'animateur
                          </span>
                            remportera directement le cadeau ci-dessous.
                          </p>

                          <p v-if="form.gift.is_digital && form.gift.pivot.recipient_digital_amount === 'salepoint'">
                            <v-divider class="my-5"/>
                            <strong class="fs-15">Information du point de vente : </strong>
                            <span class="d-block mt-2">
                             <span class="font-weight-medium">Nom:</span> {{ form.salepoint.name }}
                         </span>
                            <span class="d-block mt-2">
                             <span class="font-weight-medium">Code :</span> {{ form.salepoint.code }}
                         </span>
                            <span class="d-block mt-2">
                             <span class="font-weight-medium">Numéro de téléphone :</span> {{ form.salepoint.phone }}
                         </span>
                            <span class="d-block mt-2">
                             <span class="font-weight-medium">Non de magasin :</span> {{ form.salepoint.store_name }}
                         </span>
                            <span class="d-flex mt-2 align-center">
                            <GiftyWhiteIcon/> Compte GIFTY :
                           {{ form.salepoint.has_gifty_account ? "Oui" : "Non" }}
                         </span>
                          </p>

                        </v-alert>

                        <v-card v-if="(form.campaign.gifts.length && form.campaign.choice_gift_type ==='none')
                         ||
                          (form.campaign.gifts.length && form.campaign.choice_gift_type ==='unique')"
                                :style="{'border': 'solid 2px '+$vuetify.theme.themes.light.secondary}"
                                outlined>
                          <v-list-item v-for="(gift,i) in form.campaign.gifts" :key="i">
                            <v-list-item-avatar size="50" tile>
                              <v-img :src="$baseUrl + gift.image"
                                     class="zoom-pointer rounded"
                                     contain
                                     @click="$refs.lightbox.open(gift.image)"
                              ></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title v-text="gift.name"></v-list-item-title>
                              <v-list-item-subtitle v-if="gift.is_digital" class="font-weight-medium primary--text">
                                {{ gift.pivot.digital_amount }} DZD
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar tile>
                              <v-icon color="secondary">mdi-check-circle</v-icon>
                            </v-list-item-avatar>
                          </v-list-item>
                        </v-card>

                        <div v-if="form.campaign.gifts.length && form.campaign.choice_gift_type ==='multiple'">

                          <v-card v-for="(gift,i) in form.campaign.gifts" :key="i" class="mb-3" outlined>

                            <v-list-item @click="form.gift = gift">
                              <v-list-item-avatar size="50" tile>
                                <v-img :src="$baseUrl + gift.image"
                                       class="zoom-pointer rounded"
                                       contain
                                       @click="$refs.lightbox.open(gift.image)"
                                ></v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title v-text="gift.name"></v-list-item-title>
                                <v-list-item-subtitle v-if="gift.is_digital" class="font-weight-medium primary--text">
                                  {{ gift.pivot.digital_amount }} DZD
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-avatar tile>
                                <v-icon color="secondary" v-if="form.gift === gift">mdi-check-circle</v-icon>
                                <v-icon v-else>mdi-circle</v-icon>
                              </v-list-item-avatar>
                            </v-list-item>
                          </v-card>
                        </div>


                      </div>

                      <div v-if="form.gift.is_digital && form.gift.pivot.recipient_digital_amount === 'consumer'">
                        <SendDigitalGift :form.sync="form" class="pt-5" @add-to-sale="autoAddToSale"/>
                      </div>
                    </div>

                  </v-card-text>

                </v-card>

                <div class="d-flex justify-space-between mt-5 align-center">

                  <v-btn v-if="step === 2"
                         color="primary"
                         depressed
                         text
                         @click="step = 1">
                    <v-icon left>mdi-arrow-left</v-icon>
                    Retour
                  </v-btn>

                  <v-spacer/>

                  <div v-if="step === 1">

                    <div v-if="form.campaign.customer_info === 'nullable'">
                      <v-btn color="secondary"
                             depressed
                             @click="next('next')">
                        Suivant
                        <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>

                    <div v-else>
                      <v-btn v-if="form.sale_type !=='refusal'
                 && form.contact_type !=='indirect'
                && form.campaign.note_contact"
                             color="primary"
                             depressed
                             @click="next('next')">
                        Suivant
                        <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>

                      <v-btn v-if="form.sale_type ==='refusal'
                   || !form.campaign.note_contact
                   || form.contact_type ==='indirect'"
                             color="secondary"
                             depressed
                             @click="next('save')">
                        <v-icon left>mdi-content-save</v-icon>
                        sauvgarder
                      </v-btn>
                    </div>


                  </div>


                  <div v-if="step === 2">
                    <v-btn v-if="(form.sale_type === 'sale' && form.campaign_product_qty >=  form.campaign.min_qty_product_win)
                    ||
                   (form.campaign.choice_gift_type !== 'none')
                   ||
                   (form.sale_type === 'switch' && form.campaign_product_qty >=   form.campaign.min_qty_product_win)"
                           :disabled="!!(!Object.keys(form.gift).length
                       ||
                       (form.gift.is_digital && form.gift.pivot.recipient_digital_amount === 'consumer' &&!form.send_gift_done))"
                           color="secondary"
                           depressed
                           @click="addToSale">
                      <v-icon left>mdi-content-save</v-icon>
                      sauvgarder
                    </v-btn>

                    <v-btn v-if="(form.sale_type === 'sale' && form.campaign_product_qty <  form.campaign.min_qty_product_win)
                   ||
                   (form.sale_type === 'switch' && form.campaign_product_qty <  form.campaign.min_qty_product_win)"
                           color="secondary"
                           depressed
                           @click="addToSale">
                      <v-icon left>mdi-content-save</v-icon>
                      sauvgarder
                    </v-btn>

                  </div>

                </div>
              </div>


            </v-col>
          </v-row>

          <Lightbox ref="lightbox"/>
          <ConfirmDialog ref="confirmDialog"/>

        </v-card-text>
      </v-card>
    </v-dialog>

    <EndPointingDialog ref="endPointingDialog" @closeDialog="[dialog = false,$emit('remove-query')]"/>

  </div>
</template>
<script>
import JackpotGame from "@/views/animator-view/campaigns/program/components/JackpotGame.vue";
import SpintowinGame from "@/views/animator-view/campaigns/program/components/SpintowinGame.vue";
import EndPointingDialog from "@/views/animator-view/campaigns/program/components/EndPointingDialog.vue";
import {HTTP} from "@/http-common";
import moment from "moment/moment";
import {v4 as uuidv4} from 'uuid';
import SalesStatisticCard from "@/views/animator-view/campaigns/program/components/SalesStatisticCard.vue";
import SendDigitalGift from "@/views/animator-view/campaigns/program/components/SendDigitalGift.vue";
import GiftyWhiteIcon from "@/components/svg-icons/GiftyWhiteIcon.vue";

export default {
  components: {GiftyWhiteIcon, SendDigitalGift, SalesStatisticCard, EndPointingDialog, SpintowinGame, JackpotGame},
  data() {
    return {
      is_backup: false,
      statLoading: false,
      pointing: {},
      offline: false,
      dialog: false,
      new_form: false,
      step: 1,

      products: [],
      brands: [],

      form: {
        uuid: '',
        created_at: '',
        start_time: '',
        end_time: '',
        is_sync: false,
        campaign: {},
        salepoint: {},
        date: null,
        sale_type: 'none',
        contact_type: 'none',

        gift: {},
        send_gift_done: false,
        customer: {
          name: '',
          phone: '',
          age_range: '',
          gender: '',
        },

        original_product_id: '',
        original_brand_id: '',
        original_product_qty: '',

        campaign_product_id: '',
        campaign_brand_id: '',
        campaign_product_qty: 1,

      },

      saleStatistics: {},

      isValid: false,
      errors: {
        customer_name: '',
        customer_phone: '',
        customer_age_range: '',
        customer_gender: '',

        original_brand_id: '',
        original_product_id: '',
        original_product_qty: '',

        campaign_product_id: '',
        campaign_brand_id: '',
        campaign_product_qty: '',
      }
    }
  },
  methods: {
    next(type) {
      this.isValid = true
      this.resetErrors()

      /**
       * For test only
       */

      if (this.form.campaign.customer_info === 'nullable') {
        if (!this.form.customer.phone) {
          this.isError()
          this.errors.customer_phone = "Ce champ est obligatoire"
        } else {
          if (!this.form.customer.phone.match(/^(5|6|7)[0-9]{8}$/)) {
            this.isError()
            this.errors.customer_phone = "Ce numéro n'est pas valide"
          }
        }
      }

      /**
       * For all types
       */
      if (!this.form.customer.gender) {
        this.isError()
        this.errors.customer_gender = "Ce champ est obligatoire"
      }

      if (!this.form.customer.age_range) {
        this.isError()
        this.errors.customer_age_range = "Ce champ est obligatoire"
      }

      /***
       *  Contact direct && sale type switch or sale
       */
      if (type === 'next') {

        if (this.form.sale_type === 'switch' || this.form.sale_type === 'sale') {
          if (this.form.campaign.customer_info === 'required') {
            if (this.form.customer.name.length < 5) {
              this.isError()
              this.errors.customer_name = "Ce champ doit comporter moins de 5 caractères"
            }
            if (!this.form.customer.phone.match(/^(5|6|7)[0-9]{8}$/)) {
              this.isError()
              this.errors.customer_phone = "Ce numéro n'est pas valide"
            }
          }
        }

        if (this.form.sale_type === 'switch' || this.form.sale_type === 'refusal' || this.form.sale_type === 'none') {
          if (!this.form.original_product_id) {
            this.isError()
            this.errors.original_product_id = "Ce champ est obligatoire"
          }
          if (!this.form.original_brand_id) {
            this.isError()
            this.errors.original_brand_id = "Ce champ est obligatoire"
          }
          if (this.form.sale_type === 'refusal' || this.form.sale_type === 'none') {
            if (!this.form.original_product_qty) {
              this.isError()
              this.errors.original_product_qty = "Ce champ est obligatoire"
            }
          }
        }

        if (this.form.sale_type === 'switch' || this.form.sale_type === 'sale') {
          if (!this.form.campaign_product_id) {
            this.isError()
            this.errors.campaign_product_id = "Ce champ est obligatoire"
          }
          if (!this.form.campaign_brand_id) {
            this.isError()
            this.errors.campaign_brand_id = "Ce champ est obligatoire"
          }
          if (!this.form.campaign_product_qty) {
            this.isError()
            this.errors.campaign_product_qty = "Ce champ est obligatoire"
          }
          if (parseInt(this.form.campaign_product_qty) < 1 || isNaN(parseInt(this.form.campaign_product_qty))) {
            this.isError()
            this.errors.campaign_product_qty = "La quantité doit être supérieure à zéro"
          }
        }
        if (this.isValid) {
          this.step = 2
          this.resetErrors()
        }
      }
      /**
       * Indirect contact && refual
       */
      else {
        if (!this.form.original_product_id) {
          this.isError()
          this.errors.original_product_id = "Ce champ est obligatoire"
        }
        if (!this.form.original_brand_id) {
          this.isError()
          this.errors.original_brand_id = "Ce champ est obligatoire"
        }

        if (!this.form.original_product_qty) {
          this.isError()
          this.errors.original_product_qty = "Ce champ est obligatoire"
        }

        if (this.isValid) {
          this.addToSale()
        }
      }
    },
    isError() {
      this.isValid = false
      this.$errorMessage = "Erreur de validation merci de la corriger !"
    },
    open(campaign, salepoint, date, pointing, is_backup) {
      this.is_backup = is_backup
      this.pointing = pointing

      this.form.campaign = campaign

      if (campaign.with_game){
        this.form.gift = {}
      }

      if (!campaign.with_game && campaign.choice_gift_type === 'unique'){
        this.form.gift = campaign.gifts[0]
      }

      if (!campaign.with_game && campaign.choice_gift_type === 'multiple'){
        this.form.gift = {}
      }

      this.form.salepoint = salepoint
      this.form.date = date
      this.form.sale_type = campaign.note_contact ? 'direct' : 'none'

      this.products = this.$store.state.products
      this.brands = this.$store.state.brands

      this.getSalesStatistics()

      this.new_form = false
      this.dialog = true
    },
    async addToSale() {
      let uuid = uuidv4()
      if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir sauvegarder !', {
        icon: 'mdi-information',
        agreeText: 'Sauvegarder ',
        color: 'orange',
      })) {
        this.newSale(uuid)
      }
    },
    startFilling() {
      this.new_form = true
      this.form.start_time = moment().format('HH:mm:ss')

      /**** Only For test**/
      if (this.form.campaign.products && this.form.campaign.brands && this.form.campaign.products.length && this.form.campaign.brands.length){
        this.form.original_brand_id = this.brands[0]["id"]

        this.form.original_product_id = this.products.filter(el => el.brand_id === this.brands[0]["id"])[0]["id"]

        this.form.original_product_qty = this.form.campaign.min_qty_product_win

        this.form.campaign_brand_id = this.form.campaign.brands[0]["id"]

        const filteredProducts = this.form.campaign.products.filter(el => el.brand_id === this.form.campaign.brands[0]["id"])
        if (filteredProducts.length > 0) {
          this.form.campaign_product_id = filteredProducts[0]["id"]
        }

        this.form.campaign_product_qty = this.form.campaign.min_qty_product_win
      }


      this.form.customer.name = "Default client"
      this.form.customer.age_range = "26-35"
      this.form.customer.gender = "man"
      /*** End ***/
    },
    autoAddToSale() {
      let uuid = uuidv4()
      this.newSale(uuid)
    },
    newSale(uuid) {
      let form = JSON.parse(JSON.stringify(this.form))
      form['uuid'] = uuid
      form['created_at'] = moment().format('YYYY-MM-DD HH:mm:ss')
      form['end_time'] = moment().format('HH:mm:ss')
      this.$store.dispatch('addToSale', form)
      this.getSalesStatistics()
      this.resetForm()
      this.resetErrors()
      this.$successMessage = "Toutes les données ont été enregistrées avec succès."
      this.new_form = false
    },
    getSalesStatistics() {
      this.statLoading = true
      HTTP.get('/animator-view/sales-statistics', {
        params: {
          salepoint_id: this.form.salepoint.id,
          campaign_id: this.form.campaign.id,
          date: this.form.date,
        }
      }).then((res) => {
        this.statLoading = false
        this.offline = false
        this.saleStatistics = res.data.data
      }).catch(err => {
        this.statLoading = false
        console.log(err)

        if (!err.response) {
          this.offline = true
        }

      })
    },
    async close() {
      if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir fermer !', {
        icon: 'mdi-information',
        agreeText: 'Fermer ',
        color: 'orange',
      })) {
        this.step = 1
        this.dialog = false
      }
    },
    resetForm() {
      this.step = 1
      this.form.uuid = ''
      this.form.created_at = ''
      this.form.start_time = ''
      this.form.end_time = ''
      this.form.is_sync = false
      this.form.sale_type = this.form.campaign.note_contact ? 'switch' : 'none'
      this.form.contact_type = this.form.campaign.note_contact ? 'direct' : 'none'
      this.form.gift = this.form.campaign.with_game ? {} : this.form.campaign.gifts[0]
      this.form.send_gift_done = false
      this.form.customer = {
        name: '',
        phone: '',
        age_range: '',
      }
      this.form.original_product_id = ''
      this.form.original_brand_id = ''
      this.form.original_product_qty = ''

      this.form.campaign_product_id = ''
      this.form.campaign_brand_id = ''
      this.form.campaign_product_qty = 1
    },
    resetErrors() {
      this.errors = {
        customer_name: '',
        customer_phone: '',
        customer_age_range: '',
        customer_gender: '',

        original_brand_id: '',
        original_product_id: '',
        original_product_qty: '',

        campaign_product_id: '',
        campaign_brand_id: '',
        campaign_product_qty: '',
      }
    }
  },
  computed: {
    filtredProducts() {
      let brandId = this.form.original_brand_id
      if (brandId) {
        return this.products.filter(el => el.brand_id === brandId)
      } else {
        return this.products
      }
    },
    filtredCampaignProducts() {
      let brandId = this.form.campaign_brand_id
      if (brandId) {
        return this.form.campaign.products.filter(el => el.brand_id === brandId)
      } else {
        return this.form.campaign.products
      }
    },
    sales() {
      let sales = this.$store.state.sales.filter(el => el.campaign.id === this.form.campaign.id
          && el.salepoint.id === this.form.salepoint.id
          && el.date === this.form.date
      )
      return sales ? sales : []
    },
  },
}
</script>

<style>
.v-input--is-disabled .v-input__control > .v-input__slot {
  background: #f3f6f9;
}
</style>